import React from "react";

function Jumbotron() {
  return (
    <div class="card">
      <img
        // src="https://i.ibb.co/2cJcZhw/Adobe-Stock-333696852-Converted.png"
        src="https://i.ibb.co/CW5j7xp/Adobe-Stock-171012720-Converted.png"
        class="card-img"
        style={{ marginTop: "-100px" }}
        alt="pinkwatercolor"
        height="600px"
      />
      <div class="card-img-overlay">
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 class="card-title p-3 wow fadeIn" id="jumbotron-text">
          alyssa cucunato
        </h1>
        <div class="horizontal-line"></div>
        <h4 class="card-text p-3 wow fadeIn delay-1s" id="main-text">
          front-end web developer
          <br />
          <br />
          <div id="bio-btn">
            <a href="#bio">
              {/* <button
                type="button"
                class="btn btn-outline btn-lg wow fadeIn delay-1s"
              > */}
              <i class="fas fa-arrow-circle-down wow rotateIn delay-2s" />
              {/* </button> */}
            </a>
          </div>
        </h4>
      </div>
    </div>
  );
}

export default Jumbotron;
